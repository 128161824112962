<template>
  <div class="page">
    <!--pages/mypushhelperorders/mypushhelperorders.wxml-->
    <div class="head">
      <div class="left">
        <div>积累推广订单数</div>
        <div class="total">{{spread_order}}</div>
      </div>
      <img class="tg-img" mode="widthFix" src="~images/tuiguangrendingdan.png">
    </div>
  
    <div class="cont">
      <div>本月累计推广订单：{{spread_order}}单</div>
    </div>
	
	<div class="list">
	
	<div class="item flexcenter" v-for="(item,index) in bounsList">
	  <div class="flexcenter">
	    <img class="shape-img" mode="widthFix" src="~images/bonus-shape.png"></img>
	    <div class="desc">
	      <div class="title">{{item.spread_title}}</div>
	      <div class="time">{{item.add_time_text}}</div>
	    </div>
	  </div>
	  <div class="num">+{{item.spread_amount}}</div>
	</div>
	
	</div>
  </div>
</template>

<script>
  export default {
    name: 'mypushhelperorders',
    data() {
      return {
		filter:{},
        spread_order:0,
		bounsList:[],
      }
    },
    created() {
      this.getDetail();
      this.relateOrderList(1,true);
    },
    methods: {
      /**
       * 获取页面数据
       */
      relateOrderList:function(pageNo, override){
        let that = this;
        console.log(that.filter);
        this.post(
          this.API.relateOrderList,
          {pageNo:pageNo,pageSize:20,filter:that.filter}
        ).then(response => {
          let bounsList = response.data.orders.data;
          this.page = response.data.orders.current_page     //当前的页号
          this.totalPage = response.data.orders.last_page    //总页数
          this.totalNumber =response.data.orders.total //总条数
          this.bounsList = override ? bounsList : that.bounsList.concat(bounsList)
        })
      },
      /**
       * 获取推广员信息
       */
      getDetail:function(){
        let that = this;
        this.post(
          this.API.spreadDetail,{}
        ).then(response => {
          if(response.data.err=='1') {
            this.$toast(response.data.msg);
            setTimeout(function(){
              this.$router.back()
            },1000)
          }else{
            that.spread_order = response.data.detail.spread_order
          }
        })
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
      background-color: #F5F5F5;
  }
  
  .head {
      position: relative;
      padding: 32px;
      background-color: #F8D414;
  }
  .head .left {
      font-size: 24px;
  }
  .head .left .total {
      font-size: 56px;
      font-weight: bold;
  }
  .head .tg-img {
      position: absolute;
      right: 0;
      bottom: -40px;
      width: 141px;
  }
  
  .cont {
      padding: 32px;
      font-size: 24px;
      color: #777777;
  }
  
  .list .item {
      position: relative;
      justify-content: space-between;
      padding: 30px;
      background-color: #FFFFFF;
  }
  .list .item:after {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 90%;
      height: 1px;
      content: "";
      border-bottom: 2px solid #EEEEEE;
  }
  .list .item:last-child:after {
      border: 0;
  }
  .list .item .shape-img {
      margin-right: 30px;
      width: 90px;
  }
  .list .item .desc .title {
      font-size: 26px;
  }
  .list .item .desc .time {
      font-size: 24px;
      color: #959595;
  }
  .list .item .num {
      font-size: 36px;
      font-weight: bold;
  }

</style>
